import React, { FunctionComponent } from "react"
import { Helmet as Head } from "react-helmet"
import MutedFeed from "../components/layout/muted-feed"
import ResponsiveLayout from "../components/layout/responsive-layout"
import Notice from "../components/widgets/notice"

const MutedPage: FunctionComponent = () => {
  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <ResponsiveLayout>
        {{
          content: (
            <>
              <Notice id="muted_notice" submit="Good">
                <p>
                  If you don't want to see some authors or websites in your
                  feeds, just mute them. You find the muted list here in case
                  you change your mind later. <a href="#">Read more.</a>
                </p>
              </Notice>

              <MutedFeed />
            </>
          ),
          sidebar: null,
        }}
      </ResponsiveLayout>
    </>
  )
}
export default MutedPage
