import {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
  useRef,
} from "react"
import { unstable_batchedUpdates } from "react-dom"
import {
  faSpinnerThird,
  faInfoCircle,
  faEdit,
} from "@fortawesome/pro-regular-svg-icons"
import { faThumbsUp, faComment } from "@fortawesome/pro-light-svg-icons"
import { faThumbsUp as faThumbsUpActive } from "@fortawesome/pro-solid-svg-icons"
import { parse } from "url"
import { Channel } from "../../types"
import Banner from "../layout/banner"
import { lightTheme, darkTheme } from "../../services/theme"
import { prettyDate } from "../../services/date"
import Description from "../layout/description"
import StandaloneComment from "../comment/standalone"
import { useModal } from "../../hooks/use-modal"
import Widget from "../layout/widget"
import CommentSection from "../comment/section"
import { GET_comments } from "../../services/http"
import { useStyle } from "../../hooks/use-style"
import OptionsButton from "../layout/button/options"
import PanelImage from "../layout/panel-image"
import ActionButton from "../layout/button/action"
import AltActionButton from "../layout/button/alt-action"
import { RiChat1Line } from "react-icons/ri"
import { IconContext } from "react-icons"
import ChannelLabel from "../channel/label"
import { useUser } from "../../hooks/use-user"
import { useAnimatedCallback } from "../../hooks/use-animated-callback"
import React from "react"
import { useFilters } from "../../hooks/use-filters"
import Link from "../link"
import Avatar from "../layout/avatar"
import Button from "../layout/button"

type Props = {
  channel: Channel
  unMute: (id: string) => void
}

const MutedPreview: FunctionComponent<Props> = ({ channel, unMute }) => {
  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    tagColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  return (
    <div className="element">
      <div className="table" style={{ width: "100%" }}>
        <div className="table-cell" style={{ width: "60px" }}>
          {channel.image && channel.image.length ? (
            <div style={{ textDecoration: "none" }}>
              <Avatar
                image={channel.image || undefined}
                size={50}
                label={channel.title}
              />
            </div>
          ) : (
            channel.icon &&
            channel.icon.length && (
              <div style={{ textDecoration: "none" }}>
                <Avatar
                  image={channel.icon || undefined}
                  size={50}
                  label={channel.title}
                />
              </div>
            )
          )}
        </div>

        <div
          className="table-cell"
          style={{ width: "100%", display: "inline-table" }}
        >
          <div className="table" style={{ marginLeft: "10px" }}>
            {channel.alias_host ? (
              <>
                <strong className="table-row">{channel.title}</strong>
                <div className="table-row" style={{ fontSize: "12px" }}>
                  on {channel.alias_host}
                </div>
              </>
            ) : (
              <>
                <strong className="table-row">{channel.title}</strong>
                <div className="table-row" style={{ fontSize: "12px" }}>
                  {channel.id}
                </div>
              </>
            )}
            {/*
            <div className="table-row" style={{ fontSize: "12px" }}>
              <div className="description">{channel.description}</div>
            </div>
*/}
          </div>
          <div
            className="table-cell"
            style={{ textAlign: "right", paddingRight: "5px" }}
          >
            <Button onClick={() => unMute(channel.id)}>Unmute</Button>
          </div>
        </div>
      </div>

      <style jsx>{`
                .description {
                  padding-top: 5px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2; /* number of lines to show */
                  -webkit-box-orient: vertical;
                }
                .table {
                    display: table;
                }
                .table-cell {
                    display: table-cell;
                }
                .table-row {
                    display: table-row;
                }
                .element {
                    padding: 10px;
                    background-color: ${css.backgroundColor};
                    width: 100%;
                }
                  article {
                      /*padding: 1.5em;*/
                      /*padding-top: 20px;*/
                      /*border-radius: 0.3em;*/
                      background-color: ${css.backgroundColor};
                      /*border: 1px solid ${css.borderColor};*/
                      transition: 200ms background-color, 200ms border-color;
                      position: relative;
                      z-index: 1;
                  }
                img {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                    right: -9999px;
                    bottom: -9999px;
                    margin: auto;
                    opacity: 0;
                }
                  .interactable {
                      position: relative;
                      z-index: 1;
                  }
                  .label {
                      min-width: 0;
                  }
                  .image {
                      /*width: 40%;
                      position: absolute;
                      left: -33.333333%;
                      top: 1.5em;
                      overflow: hidden;*/
                      border-radius: 0;
                  }
                  header {
                      display: flex;
                      font-size: 0.8em;
                      overflow: hidden;
                  }
                  header > *:not(:first-child) {
                      margin-left: 1.6em;
                  }
                  h1,
                  .description {
                      margin: 0;
                  }
                  h1 {
                      font-size: 22px;
                      font-weight: 500;
                      margin-bottom: 0.5em;
                  }
                  p {
                      margin: 0;
                      font-size: 0.9em;
                      color: ${css.infoColor};
                      transition: 200ms color;
                  }
                  p:not(:first-child)::before {
                      content: "•";
                      margin-left: -0.5em;
                      padding-right: 0.5em;
                  }
                  .link {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      font-size: 0;
                  }
                  .featured-comment {
                      transform: translateY(-2em);
                      margin-bottom: -2em;
                      margin-left: 10%;
                      margin-right: 20%;
                  }
                  button {
                      border-radius: 50%;
                      height: 2em;
                      width: 2em;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border: none;
                      background-color: transparent;
                      color: inherit;
                      transition: 200ms background-color;
                      z-index: 1;
                  }
                  button:disabled {
                      opacity: 0.5;
                  }
                  button:not(:disabled):hover {
                      background-color: ${css.backgroundColor};
                  }
                  .action-icon {
                      height: 100px;
                      width: 100px;
                      color: red;
                  }
                  .tag {
                    background-color: rgb(105, 105, 105, 0.1);
                    padding:5px;
                    margin-left:2px;
                    margin-right:2px;
                    border-radius: 5px;
                    font-size: 12px;
                    user-select: none;
                  }
                  .tag:hover {
                    background-color: rgb(105, 105, 105, 0.3);
                    cursor: pointer;
                  }
                  .actions {
                    display: table-row;
                    font-size: 14px;
                    color: #111111;
                    font-weight: 400;
                  }
                  .comments_count {
                    vertical-align: middle;
                    display: table-cell;
                    padding-right: 5px;
                  }
                  .comments {
                    display: table-cell;
                  }
                  .like {
                    display: table-cell;
                    vertical-align: middle;
                    margin-left: 15px;
                    font-size: 14px;
                  }
                  .table { 
                    display: table;
                 }
                 .table-row {display: table-row; }
                 .table-cell {display: table-cell;
                  vertical-align: middle; }
                  .comment-bubble {
                    font-size: 16px;
                    padding-left:5px;
                    padding-right:10px;
                  }
                  .amount {
                    display: table-cell;
                    vertical-align: middle;
                    font-size: 14px;
                    padding-right: 5px;
                  }
                  .link {
                    min-width: 50px;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                }
                .link:hover strong {
                    text-decoration: underline;
                }
                strong {
                    margin-left: 0.5em;
                    font-size: 14px;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex: 1;
                    display: block;
                }
               }

            `}</style>
    </div>
  )
}
export default MutedPreview
