import {
  FunctionComponent,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react"
import { useLazyLoading } from "../../hooks/use-lazy-loading"
import { Channel } from "../../types"
import Loader from "../widgets/loader"
import { useFilters } from "../../hooks/use-filters"
import React from "react"
import { useIndexedDB } from "../../hooks/use-indexeddb"
import MutedPreview from "../channel/muted-preview"

const MutedFeed: FunctionComponent = () => {
  const [items, setItems] = useState<Channel[]>([])
  const { filters } = useFilters()
  const { getMutedItems, deleteKV } = useIndexedDB()

  const fetchPage = useCallback(async (offset: number) => {
    const response = await Promise.resolve(
      getMutedItems(20 * offset, 20, filters)
    )
    if (response && response.length) {
      return response
    } else return []
  }, [])

  const add = useCallback((newItems: Channel[]) => {
    if (newItems) {
      setItems(items => items.concat(newItems))
    }
    // TODO: trigger single rererender
  }, [])

  const { isLoading, isContentAvailable, resetLoader } =
    useLazyLoading<Channel>(items.length ? items : [], { fetchPage, add })

  const getContent = useCallback((items: Item[]) => {
    const itemsCount = 3
    const content: Item[] = items
    return content
  }, [])

  const content = useMemo(() => getContent(items), [items.length, filters])

  useEffect(() => {
    resetLoader()
  }, [])

  const unMute = useCallback(
    async (id: string) => {
      console.log(id)
      let r = deleteKV("muted", id)
      if (r) {
        r.onsuccess = function (e) {
          console.log("Removed")
        }
        r.onerror = function (e) {
          console.log("error")
        }
      }
      setItems(items => items.filter(item => item.id !== id))
    },
    [deleteKV, setItems, items]
  )

  return (
    <div>
      <ul>
        {items.map(x => (
          <li key={x.id}>
            <MutedPreview channel={x} unMute={unMute} />
          </li>
        ))}
      </ul>

      {isLoading ? (
        <div className="loader">
          <Loader loading={isLoading} />
        </div>
      ) : (
        !content.length && !isContentAvailable && <p>{/*No results*/}</p>
      )}

      <style jsx>
        {`
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          li:not(:first-child) {
            margin-top: 1.5em;
          }
          li {
            margin-bottom: 1.5em;
          }
          .loader {
            padding: 5em 1em;
            text-align: center;
          }
          span {
            margin-left: 0.5em;
          }
          p {
            margin-top: 100px;
            padding: 20px;
            text-align: center;
          }
        `}
      </style>
    </div>
  )
}

export default MutedFeed
